import styled from "styled-components"

import { CircularProgress, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { useDebouncedCallback } from "use-debounce"

import { colors } from "src/ui/colors"
import XIcon from "src/ui/icons/x.svg"
import { spacing } from "src/ui/spacing"
import { Maybe } from "src/utils/tsUtil"

export interface MultiPillSelectResult {
  name: string
  id: string
  inputValue?: string
}

export function MultiPillSelect({
  value,
  onSelected,
  onCreate,
  options,
  onSearch,
  loading,
  debounceTime = 500,
}: {
  value: MultiPillSelectResult[]
  onSelected: (selected: MultiPillSelectResult[]) => void
  onCreate?: (input: string) => void
  options: Maybe<MultiPillSelectResult[]>
  onSearch: (input: string) => void
  loading: boolean
  debounceTime?: number
}) {
  const debouncedSearch = useDebouncedCallback(
    (search: string) => onSearch(search),
    debounceTime,
    { leading: true }
  )

  const shouldCreate = !!onCreate

  function handleDelete(id: string) {
    const filteredSelections = value.filter((v) => v.id !== id)

    onSelected(filteredSelections)
  }

  return (
    <Autocomplete
      options={options ?? []}
      value={value}
      multiple
      onClose={() => onSearch("")}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      onInputChange={(event, input, reason) => {
        if (reason === "input") {
          debouncedSearch(input)
        }

        if (reason === "clear" || reason === "reset") {
          debouncedSearch("")
        }
      }}
      onChange={(event, value, reason, details) => {
        if (shouldCreate && details?.option && details?.option.inputValue) {
          onCreate(details.option.inputValue)
        } else {
          onSelected(value)
        }
      }}
      filterOptions={(options, state) => {
        const filteredOptions = options.filter((o) => {
          if (value.findIndex((v) => v.id === o.id) !== -1) {
            return false
          }
          return true
        })

        const exactMatch =
          value.findIndex(
            (v) => v.name.toLowerCase() === state.inputValue.toLocaleLowerCase()
          ) !== -1

        if (
          shouldCreate &&
          !exactMatch &&
          filteredOptions.length === 0 &&
          state.inputValue.length > 0
        ) {
          return [
            {
              id: "new_value",
              name: `Add "${state.inputValue}"`,
              inputValue: state.inputValue,
            },
          ]
        }

        return filteredOptions
      }}
      renderTags={(value) => (
        <TagsWrapper>
          {value.map((v) => (
            <StyledPill key={v.id}>
              <PillLabel>{v.name}</PillLabel>
              <PillButton onClick={() => handleDelete(v.id)}>
                <XIcon width={11} />
              </PillButton>
            </StyledPill>
          ))}
        </TagsWrapper>
      )}
    />
  )
}

const StyledPill = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.backgroundGray};
  color: ${colors.recommendedGray};
  border-radius: 50px;
  overflow: hidden;
`

const PillLabel = styled.div`
  padding-block: ${spacing.XS};
  padding-left: ${spacing.S};
  padding-right: ${spacing.XS};
`

const PillButton = styled.button`
  all: unset;
  padding-block: ${spacing.XS};
  padding-right: ${spacing.S};
  padding-left: ${spacing.XS};
  cursor: pointer;

  &:hover {
    color: ${colors.semanticEmergency};
  }
`

const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
  flex-wrap: wrap;
`
